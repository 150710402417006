import Image from "next/image";
import { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import auditSvg from "public/assets/images/badges/audit.svg";
import kycSvg from "public/assets/images/badges/kyc.svg";
import todaysBestSvg from "@cybertools/ui/assets/images/badges/todaysBest.svg";
import amaSvg from "public/assets/images/badges/ama.svg";
import airdropSvg from "public/assets/images/badges/airdrop.svg";
import vote5000Svg from "public/assets/images/badges/vote5000.svg";

const StyledImage = styled(Image)`
  ${({ disabled }) => disabled && `opacity: 0.1;`};
`;

const srcByType = {
  audit: auditSvg,
  kyc: kycSvg,
  todaysBest: todaysBestSvg,
  ama: amaSvg,
  airdrop: airdropSvg,
  vote5000: vote5000Svg,
};

export default function BadgeIcon({ type, size = "medium", ...rest }) {
  const theme = useContext(ThemeContext);
  const dimension = parseInt(theme.icon.size[size] || size);
  const src = srcByType[type];

  return (
    <StyledImage
      src={src.src}
      width={dimension}
      height={dimension}
      alt={type}
      {...rest}
    />
  );
}
