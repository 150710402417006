import { useAirdrop } from "@organisms/coin/provider";
import { Box, Text, Anchor, Tip } from "grommet";
import Link from "next/link";
import useIsLarge from "@cybertools/ui/es/hooks/useIsLarge";
import colorByStatus from "./colorByStatus";

const labelByStatus = {
  progress: "Airdrop on progress. Learn more on the coin's Airdrop page",
  pending: "Airdrop will take place. Learn more on the coin's Airdrop page",
  finished: "Airdrop has finished. Learn more on the coin's Airdrop page",
};

const Tooltip = ({ children }) => {
  return (
    <Box
      width={{ max: "250px" }}
      background="#26272C"
      animation={{ type: "fadeIn", duration: 600, delay: 400 }}
      round="xsmall"
      pad="small"
      gap="xsmall"
      border={{ side: "all", color: "#44444466" }}
    >
      <Text size="small" color="light-4" textAlign="center">
        {children}
      </Text>
    </Box>
  );
};

export default function Badge({ slug }) {
  const { status } = useAirdrop(slug);
  const large = useIsLarge();
  return (
    <Link href={`/coin/${slug}/airdrop`} passHref legacyBehavior>
      <Anchor>
        <Tip content={<Tooltip>{labelByStatus[status]}</Tooltip>} plain>
          <Box
            border
            round="medium"
            direction="row"
            gap="xxsmall"
            align="center"
            pad={{ horizontal: "xsmall", vertical: large ? "6px" : "" }}
            background="#2F3441"
          >
            <Box
              background={colorByStatus[status]}
              pad="xxsmall"
              round="full"
              border
            />
            <Text size="xsmall" weight="normal">
              AIRDROP
            </Text>
          </Box>
        </Tip>
      </Anchor>
    </Link>
  );
}
