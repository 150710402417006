import styled from "styled-components";
import Link from "next/link";

export const StyledLink = styled.a.attrs(({ first }) =>
  first ? {} : { tabIndex: "-1" }
)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-decoration: none;
  color: inherit;

  &:focus {
    outline: 0;
  }
`;

export default function CellLink({
  gaCategory,
  slug,
  first,
  index,
  navigate = "",
}) {
  return (
    <Link href={`/coin/${slug}${navigate}`} passHref legacyBehavior>
      <StyledLink
        first={first}
        aria-label="Coin page"
        data-ga={`${gaCategory}::click::${slug}::${index}`}
      ></StyledLink>
    </Link>
  );
}
