import { Text } from "grommet";
import styled from "styled-components";
import { useCoin } from "@organisms/coin/provider";

const types = {
  BSC: {
    title: "Binance Smart Chain",
  },
  ETH: {
    title: "Ethereum Chain",
  },
  TRX: {
    title: "Tron Chain",
  },
  MATIC: {
    title: "Polygon Matic Chain",
  },
  KCC: {
    title: "KCC Chain",
  },
};

const Container = styled(Text)`
  cursor: default;
  white-space: nowrap;
`;

export default function Network({ slug, ...rest }) {
  const { network } = useCoin(slug);
  const { title } = types[network] || {};

  return (
    <Container wordBreak="break-all" color="dark-4" title={title} {...rest}>
      {network}
    </Container>
  );
}
