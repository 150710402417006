import styled from "styled-components";
import { Table } from "grommet";
import { StyledLink } from "./cellLink";
import { linkCss } from "./withLink";

const Container = styled(Table)`
  & > tbody:not(.nestedTable) > tr:not(.nestedRow) {
    cursor: pointer;
  }

  & > tbody:not(.nestedTable) > tr:not(.nestedRow):hover > td,
  & > tbody:not(.nestedTable) > tr:not(.nestedRow):focus-within > td {
    background-color: #202739;
  }

  & > tbody:not(.nestedTable) > tr:not(.nestedRow):not(:last-child) {
    border-bottom: 1px solid
      ${({ promoted }) => (promoted ? "#262F45" : "#313d5b")};
  }

  th:first-child {
    border-top-left-radius: 4px;
  }

  th:last-child {
    border-top-right-radius: 4px;
  }

  th {
    background-color: ${({ promoted }) => (promoted ? `#141824` : `inherit`)};
    border-bottom: none;
  }
  td {
    position: relative;
    background-color: ${({ promoted }) => (promoted ? `#141824` : `inherit`)};
  }

  td {
    a:not(${StyledLink}),
    button {
      ${linkCss}
    }
  }

  & > tbody:not(.nestedTable) > tr:first-child {
    ${({ hasHeader }) =>
      hasHeader
        ? `th:first-child {
        border-top-left-radius: 4px;
      }
      th:last-child {
        border-top-right-radius: 4px;
      }`
        : `td:first-child {
        border-top-left-radius: 4px;
      }
      td:last-child {
        border-top-right-radius: 4px;
      }`}
  }

  & > tbody:not(.nestedTable) > tr:not(.nestedRow):last-child {
    td:first-child {
      border-bottom-left-radius: 4px;
    }
    td:last-child {
      border-bottom-right-radius: 4px;
    }
  }
`;

export default Container;
