import { Anchor } from "grommet";
import Link from "next/link";
import Container from "components/text";

export default function WatchlistAdded() {
  return (
    <Container>
      Added to{" "}
      <Link href="/watchlist" passHref legacyBehavior>
        <Anchor label="watchlist" />
      </Link>
    </Container>
  );
}
