import { Box } from "grommet";

export default function DividerCircle(props) {
  return (
    <Box
      pad="1px"
      background="status-unknown"
      round="full"
      alignSelf="center"
      flex={false}
      reponsive={false}
      {...props}
    />
  );
}
