import Image from "next/legacy/image";
import { Box, Anchor } from "grommet";
import styled from "styled-components";
import coingeckoImg from "public/assets/images/coingecko.png";
import coinmarketcapImg from "public/assets/images/coinmarketcap.png";
import geckoTerminalImg from "public/assets/images/geckoterminal.png";

const StyledAnchor = styled(Anchor)`
  line-height: 1;
`;

export default function Listings({
  coingecko,
  cmc,
  geckoTerminal,
  size = 22,
  ...rest
}) {
  return (
    <Box
      direction="row"
      gap="xsmall"
      align="center"
      flex={false}
      responsive={false}
      {...rest}
    >
      {coingecko && (
        <StyledAnchor
          href={coingecko}
          target="_blank"
          title="Go to Coingecko listing"
          rel="noopener"
          size="small"
        >
          <Image
            src={coingeckoImg}
            alt="Coingecko"
            height={size}
            width={size}
            objectFit="cover"
          />
        </StyledAnchor>
      )}
      {geckoTerminal && (
        <StyledAnchor
          href={geckoTerminal}
          target="_blank"
          title="GeckoTerminal Website"
          rel="noopener"
          size="small"
          margin={coingecko ? { left: "2px" } : "none"}
        >
          <Image
            src={geckoTerminalImg}
            alt="GeckoTerminal"
            height={size}
            width={size}
            objectFit="cover"
          />
        </StyledAnchor>
      )}
      {cmc && (
        <StyledAnchor
          href={cmc}
          target="_blank"
          rel="noopener"
          title="Go to CoinMarketCap listing"
          size="small"
          margin={geckoTerminal ? { left: "2px" } : "none"}
        >
          <Image
            src={coinmarketcapImg}
            alt="CoinMarketCap"
            height={size}
            width={size}
            objectFit="cover"
          />
        </StyledAnchor>
      )}
    </Box>
  );
}
