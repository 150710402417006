import { forwardRef } from "react";
import styled, { css } from "styled-components";

export const linkCss = css`
  position: relative;
  z-index: 1;
`;

export default function (Component) {
  const Container = styled(Component)`
    ${linkCss}
  `;

  function WithLink(props, ref) {
    return <Container {...props} ref={ref} />;
  }

  return forwardRef(WithLink);
}
