import { Button, Text } from "grommet";
import { Star } from "grommet-icons";
import styled, { css } from "styled-components";
import { useToggleWatchlist } from "@organisms/watchlist/actions";
import { useWatchlisted, useCoin } from "@organisms/coin/provider";

const enabledCss = css`
  fill: #ffc95e;
`;

const emptyCss = css`
  fill: transparent;

  path {
    stroke: #f8f8f8;
    stroke-width: 1px;
  }
`;

const StyledButton = styled(Button)`
  && {
    svg {
      ${({ enabled }) => (enabled ? enabledCss : emptyCss)}
    }

    ${({ disabled }) =>
      !disabled &&
      `
      &:hover svg {
        transform: scale(1.2);
      }
    `}
  }
`;

const getLabel = (watchlists) =>
  `In ${watchlists} ${watchlists === 1 ? "watchlist" : "watchlists"}`;

export default function Watchlist({ slug, short, ...rest }) {
  const { watchlists } = useCoin(slug);
  const watchlisted = useWatchlisted(slug);
  const [toggle] = useToggleWatchlist(slug);

  return (
    <StyledButton
      onClick={toggle}
      icon={<Star />}
      label={
        !short && (
          <Text truncate size="small">
            {getLabel(watchlists)}
          </Text>
        )
      }
      title={watchlisted ? "Remove from watchlist" : "Add to watchlist"}
      plain
      enabled={watchlisted}
      gap="xsmall"
      {...rest}
    />
  );
}
